import React, { useState, useEffect } from 'react';
import { Card, Tabs, Button, Typography, Space, Row, Col, Table, Modal, message, Avatar, Popconfirm, Tooltip, Grid } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CompanyService from '../../services/CompanyService';
import { CheckCircleOutlined, MinusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './Company.scss';
import ControlAddressDrawer from './ControlAddressDrawer';
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import AuthorizedPersonDrawer from './AuthorizedPersonDrawer';
import CompanyEditDrawer from './CompanyEditDrawer';
import ControlAddress from './ControlAddress';
import AuthorizedPersonService from '../../services/AuthorizedPersonService';
import { capitalizeSentences, capitalizeWords, openNotification } from '../../utils';
import './Company.scss';
interface Address {
  id: number; // Address ID'yi de ekledim
  addressLine1: string;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  phone: string;
  taxNumber: string;
  taxOffice: string;
  title: string;
  type: any;
}

interface AuthorizedPerson {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phoneNumber: string;
  default: boolean;
}

interface Company {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  kepAddress: string;
  taxOffice: string;
  taxNumber: string;
  imageId: number;
  createdAt: string;
  accountId: any;
  addresses: Address[];
  authorizedPersons: AuthorizedPerson[];
}

const { TabPane } = Tabs;
const { Text } = Typography;

const CompanyDetailCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [company, setCompany] = useState<Company | null>(null);
  const [companyId, setCompanyId] = useState<any>();
  const [image, setImage] = useState<any>('');
  const [isEditDrawerVisible, setEditDrawerVisible] = useState(false);
  const [isAuthorizedPersonDrawerVisible, setAuthorizedPersonDrawerVisible] = useState(false);
  const [editPerson, setEditPerson] = useState<AuthorizedPerson | null>(null);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [authorizedPersons, setAuthorizedPersons] = useState<AuthorizedPerson[]>([]);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = !screens.lg;
  let userId = localStorage.getItem('id');
  let isPartner = localStorage.getItem('isPartner');

  const fetchAuthorizedPersons = async () => {
    try {
      const response = await AuthorizedPersonService.getCompanyAuthorizedPersons(companyId);
      let authorizedPersons = response.data;

      authorizedPersons.sort((a: any, b: any) => (b.default === true ? 1 : 0) - (a.default === true ? 1 : 0));

      setAuthorizedPersons(authorizedPersons);
      console.log(authorizedPersons);
    } catch (error) {
      console.error('Authorized persons could not be fetched:', error);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchAuthorizedPersons();
    }
  }, [companyId]);

  const handleEditPersonClick = (person: AuthorizedPerson) => {
    setEditPerson(person);
    setAuthorizedPersonDrawerVisible(true);
  };

  const handleAuthorizedPersonDrawerClose = () => {
    setAuthorizedPersonDrawerVisible(false);
    setEditPerson(null);
    fetchAuthorizedPersons();

  };
  const fetchCompany = async () => {
    try {
      const response = await CompanyService.getCompany(id);
      if (response.data.imageId) {
        const imageResponse = await CompanyService.getImage(response.data.imageId);
        setImage(imageResponse.data.preSignUrl);
        console.log('Logo yüklendi:', imageResponse.data.preSignUrl);
      }
      setCompanyId(response.data.id);
      setCompany(response.data);
    } catch (error) {
      console.error('Firma bilgileri yüklenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, [id]);

  const handleEditClick = () => {
    setEditDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setEditDrawerVisible(false);
    fetchCompany();
  };

  const handleDeleteAuthoriezPerson = async (AuthorizedPerson: AuthorizedPerson) => {
    try {
      await AuthorizedPersonService.remove(AuthorizedPerson.id);
      openNotification("success", "Başarılı", "Yetkili başarıyla silindi");
      fetchAuthorizedPersons();
      fetchCompany();
    } catch (error) {
      console.error('Yetkili silinirken hata oluştu:', error);
      openNotification("error", "Hata", 'Yetkili silinemedi')
    }
  };


  const authorizedPersonColumns = [
    {
      title: 'Ad',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: string, record: AuthorizedPerson) => (
        <Text>{capitalizeWords(record.firstName)}</Text>
      ),
    },
    {
      title: 'Soyad',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text: string, record: AuthorizedPerson) => (
        <Text>{capitalizeWords(record.lastName)}</Text>
      ),
    },
    {
      title: 'Ünvan',
      dataIndex: 'position',
      key: 'position',
      render: (text: string, record: AuthorizedPerson) => (
        <Text>{capitalizeSentences(record.position)}</Text>
      ),
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Cep Telefonu',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text: string, record: AuthorizedPerson) => (
        <Space>
          <Button
            icon={<img src={PenIcon} alt="pen" className="icon" />}
            shape="circle"
            onClick={() => handleEditPersonClick(record)}
            className='icon-button'
          />

          {record.default == true ?
            <Tooltip title="Birincil yetkiliyi silemezsin">
              <Button
                icon={<img src={TrashIcon} alt="trash" className="icon" />}
                shape="circle"
                disabled
                className='icon-button'
              />
            </Tooltip> :
            authorizedPersons.length > 1 ? (
              <Popconfirm
                title="Bu yetkiliyi silmek istediğinizden emin misiniz?"
                onConfirm={() => handleDeleteAuthoriezPerson(record)}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button
                  icon={<img src={TrashIcon} alt="trash" className="icon" />}
                  shape="circle"
                  className='icon-button'
                />
              </Popconfirm>
            ) : (

              <Tooltip title="En az bir yetkili bulunmalı">
                <Button
                  icon={<img src={TrashIcon} alt="trash" className="icon" />}
                  shape="circle"
                  disabled
                  className='icon-button'
                />
              </Tooltip>
            )}
          <Popconfirm
            title={`Bu kişiyi Birincil yetkili yapmak mı istiyorsunuz?`}
            onConfirm={() => handleSetDefault(record.id)}
            okText="Evet"
            cancelText="Hayır"
            disabled={record.default}
          >
            <Button
              icon={record.default ? <CheckCircleOutlined style={{ color: 'green' }} /> : <MinusCircleOutlined />}
              shape="circle"
              className='icon-button'
              disabled={record.default}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSetDefault = async (authorizedPersonId: number) => {
    try {
      await AuthorizedPersonService.setDefault(authorizedPersonId);
      openNotification("success", "Başarılı", 'Yetkili başarıyla Birincil yapıldı');
      fetchAuthorizedPersons();
    } catch (error) {
      console.error('Birincil yetkili ayarlanırken hata oluştu:', error);
      openNotification("error", "Hata", 'Birincil yetkili ayarlanamadı');
    }
  };


  const handleAddPersonClick = () => {
    setEditPerson(null); // Boş form için editPerson'u null yapıyoruz
    setAuthorizedPersonDrawerVisible(true);
  };

  const billingAddress = company?.addresses?.find(
    (address: any) => address.type === "BILLING"
  );

  const handleOfferNavigation = (id:any=null) => {
    if (id) {
      navigate(`/proposals/create?companyId=${id}`);
      return;
    }
    navigate(`/proposals/create`);
  };

  return (
    <Card className="company-card p-20px br-15px" >
      {company && (
        <>

          <div className='mb-20px'>
            <Row justify='space-between' align='middle' gutter={[16, 16]}>
              <div >
                <Space size={10} style={{ flexWrap: 'wrap' }}>
                  <span className='d-flex align-items-center'>
                    {image ? (
                      <img
                        src={image || ''}
                        alt={company.name}
                        className='company-image'
                      />
                    ) : (
                      <Avatar className='company-avatar'>
                        {company.name.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                    <Text strong className='fs-24px ml-10px'>
                      <Tooltip title={company.name.toUpperCase()}>
                        <span>
                          {company.name.length > 40 ? `${company.name.substring(0, 40).toUpperCase()}...` : company.name.toUpperCase()}
                        </span>
                      </Tooltip>
                    </Text>
                  </span>


                  {activeTabKey === '1' && isMobile ? (
                    <Button
                      icon={<img src={PenIcon} alt="pen" className="icon" />}
                      shape="circle"
                      onClick={handleEditClick}
                      className='icon-button'
                    />
                  ) : null}
                </Space>
              </div>

              <Space size={15}>
                <Tooltip title={isPartner?.toString() === 'false' ? 'Teklif oluşturmak için iş ortağı sözleşmesini onaylamanız gerekmektedir' : ''}>
                  <Button
                    type="primary"
                    size="large"
                    className="d-button dark-button width-auto"
                    onClick={()=>handleOfferNavigation(companyId)}
                    disabled={company.accountId != userId || isPartner?.toString() === 'false'}
                  >
                    Teklif Oluştur
                  </Button>
                </Tooltip>

                {activeTabKey === '1' && !isMobile ? (
                  <Button
                    icon={<img src={PenIcon} alt="pen" className="icon" />}
                    shape="circle"
                    onClick={handleEditClick}
                    className='icon-button'
                  />
                ) : null}
              </Space>
            </Row>
          </div>
          <Tabs defaultActiveKey="1" onChange={(key) => setActiveTabKey(key)}>
            <TabPane tab="Firma Bilgileri" key="1">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">Firma Ünvanı</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>
                    {company.name.toUpperCase()}
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">Firma E-posta Adresi</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{company.email}</div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">Firma Telefon Numarası</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{company.phoneNumber}</div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">Kep Adresi</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>
                    {company.kepAddress}
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">Vergi Dairesi</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeSentences(company.taxOffice)}</div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">Vergi Numarası</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{company.taxNumber}</div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">İl</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeSentences(billingAddress?.city) || ""}</div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">İlçe</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeSentences(billingAddress?.state) || ""}</div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Text type="secondary">Adres</Text>
                  <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeWords(billingAddress?.addressLine1) || ""}</div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Kontrol Adresi" key="2">
              <ControlAddress companyId={companyId} type={false} setSelectedAddress={() => { }} selectedAddressId={null} isEdit={false}
              />
            </TabPane>
            <TabPane tab="Yetkili Bilgileri" key="3">
              <Button
                type="primary"
                className="d-button dark-button mb-16px width-auto"
                onClick={handleAddPersonClick}
              >
                Yeni Yetkili Ekle
              </Button>
              <Table
                scroll={{ x: 1080 }}
                columns={authorizedPersonColumns}
                dataSource={authorizedPersons}
                rowKey={(record) => record.email}
                pagination={false}
              />
            </TabPane>
          </Tabs>
        </>
      )
      }

      <AuthorizedPersonDrawer
        visible={isAuthorizedPersonDrawerVisible}
        onClose={handleAuthorizedPersonDrawerClose}
        companyId={id as string}
        existingData={company as any}
        editPerson={editPerson}
      />
      <CompanyEditDrawer
        visible={isEditDrawerVisible}
        onClose={handleDrawerClose}
        companyId={id as string}
      />
    </Card >
  );
};

export default CompanyDetailCard;