import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
    Button,
    Table,
    Input,
    Row,
    Col,
    Checkbox,
    Popconfirm,
    Typography,
    Tooltip,
    Divider
} from 'antd';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import AttributeService from '../../services/AttributeService';
import ProposalService from '../../services/ProposalService';
import ToolService from '../../services/ToolService';
import { formatToTL, openNotification } from '../../utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PdfUpload from './PdfUpload';

const { Text } = Typography;

const Parametres = ({
    companyId,
    addressId,
    attributes,
    isEdit = false,
    authorizedPersonIds
}: {
    companyId: any;
    addressId: any;
    attributes: any;
    isEdit: boolean;
    authorizedPersonIds: any;
}) => {
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState<any[]>([]);
    const [tools, setTools] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [showSelected, setShowSelected] = useState(false);
    const [loading, setLoading] = useState(false);

    // Teklif hesaplaması sonucunda dönen değerler (TL)
    const [totalAmount, setTotalAmount] = useState(0);
    const [kdv, setKdv] = useState(0);
    const [totalWithKDV, setTotalWithKDV] = useState(0);
    const [revenueShare, setRevenueShare] = useState(0);
    const [offeringLogs, setOfferingLogs] = useState([]);

    // Döviz kurlarını sadece bir kez çekeceğiz
    const [usdRate, setUsdRate] = useState<number>(0);
    const [eurRate, setEurRate] = useState<number>(0);

    const [roles, setRoles] = useState<any>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const hasInitialized = useRef(false);
    const [permissions, setPermissions] = useState<string[]>([]);

    const location = useLocation();
    const navigate = useNavigate();

    const getQueryParams = (search: string) => new URLSearchParams(search);
    const queryParams = getQueryParams(location.search);
    const [showPdfUpload, setShowPdfUpload] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState<any>(null);

    // 1) permissions'ı localStorage'dan oku
    useEffect(() => {
        const storedPermissions = localStorage.getItem('permissions');
        if (storedPermissions) {
            try {
              const parsedPermissions = JSON.parse(storedPermissions);
        
              // Eğer JSON içeriği gerçekten bir array ise set et
              if (Array.isArray(parsedPermissions)) {
                setPermissions(parsedPermissions);
              } else {
                console.error('Permissions hatalı formatta:', parsedPermissions);
              }
            } catch (err) {
              console.error('Permissions parse hatası:', err);
            }
          }
    }, []);

    // 2) Tools & Attributes fetch
    useEffect(() => {
        // Tool verileri
        ToolService.getAllTools()
            .then((response) => {
                setTools(response.data);
            })
            .catch((error) => {
                console.error('Tool data fetch error:', error);
            });

        // Attribute verileri
        AttributeService.getAll()
            .then((response) => {
                const allAttributes = response.data
                    .filter((item: any) => item.isActive)
                    .sort((a: any, b: any) => {
                        // order sıralaması
                        if (a.order == null && b.order == null) {
                            return 0;
                        } else if (a.order == null) {
                            return 1;
                        } else if (b.order == null) {
                            return -1;
                        } else {
                            return a.order - b.order;
                        }
                    })
                    .map((item: any) => ({
                        ...item,
                        quantity: null,
                        period: 1,
                        // Hata mesajlarını saklamak için quantityError field ekliyoruz
                        quantityError: null
                    }));

                let initialSelectedRows: any[] = [];
                if (attributes && attributes.length > 0) {
                    // Teklif update durumu
                    const enrichedAttributes = allAttributes.map((attribute: any) => {
                        const matchedAttribute = attributes.find(
                            (attr: any) => attr.attributeId === attribute.id
                        );
                        if (matchedAttribute) {
                            const enrichedAttribute = {
                                ...attribute,
                                quantity: matchedAttribute.quantity,
                                period: matchedAttribute.period,
                                price: matchedAttribute.price,
                                fiyat: matchedAttribute.price * matchedAttribute.quantity
                            };
                            initialSelectedRows.push({
                                parametreId: enrichedAttribute.id,
                                birimFiyat: enrichedAttribute.price,
                                quantity: enrichedAttribute.quantity,
                                period: enrichedAttribute.period,
                                fiyat: enrichedAttribute.price * enrichedAttribute.quantity
                            });
                            return enrichedAttribute;
                        }
                        return attribute;
                    });
                    setData(enrichedAttributes);

                    // Tekilleştir & sırala
                    const uniqueRows = uniqueByParametreId(initialSelectedRows).sort((a, b) => {
                        const recA = enrichedAttributes.find((itm) => itm.id === a.parametreId);
                        const recB = enrichedAttributes.find((itm) => itm.id === b.parametreId);
                        const orderNoA = recA?.order ?? 999999;
                        const orderNoB = recB?.order ?? 999999;
                        return orderNoA - orderNoB;
                    });
                    setSelectedRows(uniqueRows);
                } else {
                    setData(allAttributes);
                }
            })
            .catch((error) => {
                console.error('Attributes data fetch error:', error);
            });
    }, [attributes]);

    // 3) Seçili satırlar her değiştiğinde => Teklif fiyatı hesapla
    useEffect(() => {
        handleCalculatePrice();
    }, [selectedRows]);

    // 4) Roller
    useEffect(() => {
        let storedRoles: any = localStorage.getItem('roles');
        if (storedRoles) {
            let parsedRoles = JSON.parse(storedRoles);
            setRoles(parsedRoles);
        }
    }, []);

    // 5) Döviz kurları
    useEffect(() => {
        fetch('https://api.frankfurter.app/latest?from=TRY&to=USD,EUR')
            .then((res) => res.json())
            .then((data) => {
                if (data && data.rates) {
                    setUsdRate(data.rates.USD);
                    setEurRate(data.rates.EUR);
                }
            })
            .catch((err) => {
                console.error('Döviz kurları çekilirken hata oluştu:', err);
            });
    }, []);

    // Teklif Fiyatı Hesaplama
    const handleCalculatePrice = () => {
        // Parametre ID bazında tekilleştirme
        const uniqueSelectedRows = uniqueByParametreId(selectedRows);

        const payload = {
            addressId,
            companyId,
            authorizedPersonIds,
            attributes: uniqueSelectedRows.map((row) => ({
                attributeId: row.parametreId,
                price: row.birimFiyat,
                quantity: row.quantity,
                period: row.period
            }))
        };

        ProposalService.calculatePrice(payload)
            .then((response) => {
                const {
                    totalPrice,
                    kdv,
                    totalWithKDV,
                    revenueShare,
                    offeringLogs
                } = response.data;

                setTotalAmount(totalPrice);
                setKdv(kdv);
                setTotalWithKDV(totalWithKDV);
                setRevenueShare(revenueShare);
                setOfferingLogs(offeringLogs);
            })
            .catch(() => {
                openNotification(
                    'error',
                    'Hata',
                    'Fiyat hesaplanırken bir hata oluştu.'
                );
            });
    };

    // Parametre satırı seçimi
    const handleRowSelection = (record: any) => {
        const isSelected = isRowSelected(record);
        let newSelectedRows = [...selectedRows];

        if (isSelected) {
            // Seçiliyse çıkar
            newSelectedRows = newSelectedRows.filter((row) => row.parametreId !== record.id);
            setData((prevData) =>
                prevData.map((item) =>
                    item.id === record.id
                        ? { ...item, quantityError: null }
                        : item
                )
            );
        } else {
            // Değilse ekle
            newSelectedRows.push({
                parametreId: record.id,
                birimFiyat: record.price,
                quantity: record.quantity ?? null,
                period: record.period ?? 1,
                fiyat: record.price * (record.quantity ?? 0)
            });

            // Eğer grup elemanıysa, grubun diğer elemanlarını da seç
            const group = groupedData.find((g) =>
                g.children?.some((child: any) => child.id === record.id)
            );
            if (group) {
                group.children.forEach((child: any) => {
                    if (!isRowSelected(child)) {
                        newSelectedRows.push({
                            parametreId: child.id,
                            birimFiyat: child.price,
                            quantity: child.quantity ?? null,
                            period: child.period ?? 1,
                            fiyat: child.price * (child.quantity ?? 0)
                        });
                    }
                });
            }
        }

        // Tekilleştir
        let uniqueRows = uniqueByParametreId(newSelectedRows);

        // Sıralama
        uniqueRows.sort((a, b) => {
            const recA = data.find((itm) => itm.id === a.parametreId);
            const recB = data.find((itm) => itm.id === b.parametreId);
            const orderNoA = recA?.order ?? 999999;
            const orderNoB = recB?.order ?? 999999;
            return orderNoA - orderNoB;
        });

        setSelectedRows(uniqueRows);
    };

    const isRowSelected = (record: any) => {
        return selectedRows.some((row) => row.parametreId === record.id);
    };

    // Adet/period input değişince
    const handleInputChange = (
        value: number | null,
        record: any,
        field: 'quantity' | 'period'
    ) => {
        // 1) data içindeki quantity & quantityError güncelle
        const updatedData = data.map((item) => {
            if (item.id === record.id) {
                const updatedItem = {
                    ...item,
                    [field]: value,
                    fiyat:
                        item.price *
                        (field === 'quantity'
                            ? (value ?? 0)
                            : (item.quantity ?? 0))
                };

                // Hata kontrolü: isDefault ise min 1, değilse 0 da olabilir ama null/NaN olmamalı
                if (field === 'quantity') {
                    if (item.isDefault) {
                        // Birincil ise 1'den az olamaz
                        if (value === null || value < 1 || isNaN(value)) {
                            updatedItem.quantityError = 'Bu alan minimum 1 olmalı.';
                        } else {
                            updatedItem.quantityError = null;
                        }
                    } else {
                        // Birincil değil
                        if (value === null || isNaN(value)) {
                            updatedItem.quantityError = 'Bir değer giriniz';
                        } else {
                            updatedItem.quantityError = null;
                        }
                    }
                }
                return updatedItem;
            }
            return item;
        });

        // 2) selectedRows içindeki quantity güncelle
        const updatedSelectedRows = [...selectedRows];
        const rowIndex = updatedSelectedRows.findIndex(
            (row) => row.parametreId === record.id
        );

        if (rowIndex === -1) {
            // Seçili değilse, ekle
            updatedSelectedRows.push({
                parametreId: record.id,
                birimFiyat: record.price,
                quantity: field === 'quantity' ? value : record.quantity,
                period: field === 'period' ? value : record.period,
                fiyat:
                    record.price *
                    (field === 'quantity'
                        ? (value ?? 0)
                        : (record.quantity ?? 0))
            });
        } else {
            // Seçiliyse, güncelle
            updatedSelectedRows[rowIndex][field] = value;
            updatedSelectedRows[rowIndex].fiyat =
                updatedSelectedRows[rowIndex].birimFiyat *
                (updatedSelectedRows[rowIndex].quantity ?? 0);
        }

        // Eğer grup elemanıysa, aynı gruptaki diğer elemanları da otomatik seç
        const group = groupedData.find((g) =>
            g.children?.some((child: any) => child.id === record.id)
        );
        if (group) {
            group.children.forEach((child: any) => {
                const childRowIndex = updatedSelectedRows.findIndex(
                    (row) => row.parametreId === child.id
                );
                if (childRowIndex === -1) {
                    updatedSelectedRows.push({
                        parametreId: child.id,
                        birimFiyat: child.price,
                        quantity: child.quantity ?? null,
                        period: child.period ?? 1,
                        fiyat: child.price * (child.quantity ?? 0)
                    });
                }
            });
        }

        // 3) Tekilleştir & sırala
        let uniqueRows = uniqueByParametreId(updatedSelectedRows);
        uniqueRows.sort((a, b) => {
            const recA = updatedData.find((itm) => itm.id === a.parametreId);
            const recB = updatedData.find((itm) => itm.id === b.parametreId);
            const orderNoA = recA?.order ?? 999999;
            const orderNoB = recB?.order ?? 999999;
            return orderNoA - orderNoB;
        });

        // State güncelle
        setData(updatedData);
        setSelectedRows(uniqueRows);
    };

    // Teklif Oluştur
    const handleTeklifOlustur = async () => {
        setLoading(true);
        // Grup içerisindeki birincil parametre kontrolü
        // eğer gruptan herhangi bir parametre seçiliyse o grupta isDefault parametre
        // mutlaka seçili ve quantity >= 1 olmalı
        const newSelectedRows = [...selectedRows];
        const updatedData = [...data];  // data içindeki quantityError vs. güncelleyebiliriz
        let validationError = false;

        // Tüm grupları bulalım
        const allGroups = groupedData.filter((g) => g.isGroup);
        allGroups.forEach((group) => {
            // Bu grupta herhangi bir child seçili mi?
            const groupChildIds = group.children.map((child: any) => child.id);
            const anySelectedInGroup = newSelectedRows.some((row) =>
                groupChildIds.includes(row.parametreId)
            );
            if (anySelectedInGroup) {
                // Grup içindeki birincil parametre (isDefault) var mı?
                const defaultParam = group.children.find((child: any) => child.isDefault);
                if (defaultParam) {
                    // Seçili mi?
                    const isDefaultSelected = newSelectedRows.find(
                        (row) => row.parametreId === defaultParam.id
                    );
                    // Eğer seçili değilse veya quantity < 1 => otomatik 0 yap, hata ver
                    if (!isDefaultSelected) {
                        // otomatik ekle
                        newSelectedRows.push({
                            parametreId: defaultParam.id,
                            birimFiyat: defaultParam.price,
                            quantity: 0,
                            period: defaultParam.period ?? 1,
                            fiyat: defaultParam.price * 0
                        });
                        // data içinde de quantity=0 ve quantityError
                        const idx = updatedData.findIndex((d) => d.id === defaultParam.id);
                        if (idx !== -1) {
                            updatedData[idx].quantity = 0;
                            updatedData[idx].quantityError = 'Bu alan minimum 1 olmalı.';
                        }
                        validationError = true;

                    } else if (isDefaultSelected.quantity < 1) {
                        // quantity < 1 => 0'a çek, hata
                        isDefaultSelected.quantity = 0;
                        isDefaultSelected.fiyat = defaultParam.price * 0;
                        const idx = updatedData.findIndex((d) => d.id === defaultParam.id);
                        if (idx !== -1) {
                            updatedData[idx].quantity = 0;
                            updatedData[idx].quantityError = 'Bu alan minimum 1 olmalı.';
                        }
                        validationError = true;
                    }
                }
            }
        });

        if (validationError) {
            // Tekrar state'e basıp, notification gösterip return
            setData(updatedData);
            setSelectedRows(uniqueByParametreId(newSelectedRows));
            setLoading(false);
            openNotification('error', 'Hata', 'Birincil parametre boş bırakılamaz veya 0 girilemez.');
            return;
        }

        // Duplicate check
        const duplicates = newSelectedRows.filter(
            (row, index, self) =>
                index !== self.findIndex((r) => r.parametreId === row.parametreId)
        );
        if (duplicates.length > 0) {
            openNotification('error', 'Hata', 'Aynı parametreden 2 tane seçilemez.');
            setLoading(false);
            return;
        }

        // quantity check => null veya undefined mi?
        const hasErrors = newSelectedRows.some(
            (row) => row.quantity === null || row.quantity === undefined
        );
        if (hasErrors) {
            openNotification('error', 'Hata', 'Lütfen seçili parametreler için adet giriniz.');
            setLoading(false);
            return;
        }

        // data içinde quantityError var mı?
        const foundError = updatedData.some((d) => d.quantityError);
        if (foundError) {
            openNotification('error', 'Hata', 'Hatalı veya eksik girilmiş alanları düzeltiniz.');
            setLoading(false);
            return;
        }

        if (addressId === null) {
            openNotification('error', 'Hata', 'Lütfen adres seçiniz.');
            setLoading(false);
            return;
        }
        if (!authorizedPersonIds || authorizedPersonIds.length === 0) {
            openNotification('error', 'Hata', 'Lütfen yetkili seçiniz.');
            setLoading(false);
            return;
        }
        if (newSelectedRows.length === 0) {
            openNotification('error', 'Hata', 'Lütfen parametre seçiniz.');
            setLoading(false);
            return;
        }

        // Order'a göre sıralama
        const sortedByOrderNo = [...newSelectedRows].sort((a, b) => {
            const recA = updatedData.find((itm) => itm.id === a.parametreId);
            const recB = updatedData.find((itm) => itm.id === b.parametreId);
            const orderNoA = recA?.order ?? 999999;
            const orderNoB = recB?.order ?? 999999;
            return orderNoA - orderNoB;
        });

        const payload = {
            addressId,
            companyId,
            authorizedPersonIds,
            incrementRevision: true,
            attributes: sortedByOrderNo.map((row) => ({
                attributeId: row.parametreId,
                price: row.birimFiyat,
                quantity: row.quantity,
                period: row.period
            }))
        };

        // Teklif güncelleme veya oluşturma
        if (attributes) {
            const proposalIdFromQuery: any = queryParams.get('proposalId');
            try {
                await ProposalService.update(proposalIdFromQuery, payload);
                setSelectedId(proposalIdFromQuery);
                await setShowPdfUpload(true);
                openNotification('success', 'Başarılı', 'Teklif başarıyla güncellendi');
               
            } catch (error) {
                console.error('Teklif güncelleme hatası:', error);
                openNotification('error', 'Hata', 'Teklif oluşturulurken bir hata oluştu.');
            }
        } else {
            await ProposalService.create(payload)
                .then(async (response: any) => {
                    setSelectedId(response.data);
                    await setShowPdfUpload(true);
                    openNotification('success', 'Başarılı', 'Teklif başarıyla oluşturuldu.');
                 
                })
                .catch(() => {
                    openNotification('error', 'Hata', 'Teklif oluşturulurken bir hata oluştu.');
                });
        }
    };

    // Tablonun kolonları
    const columns = [
        {
            title: 'Adı',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => {
                const isGroup = record.isGroup;
                return (
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
                        <Checkbox
                            checked={isGroup ? isGroupSelected(record) : isRowSelected(record)}
                            indeterminate={isGroup && isGroupIndeterminate(record)}
                            onChange={() =>
                                isGroup
                                    ? handleGroupCheckboxChange(record)
                                    : handleRowSelection(record)
                            }
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginTop: '0px' }}
                            disabled={!isGroup && isEdit}
                        />
                        <span style={{ fontWeight: isGroup ? 'bold' : 'normal' }}>
                            {text}
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description',
            render: (text: string) => <span>{text}</span>
        },
        {
            title: 'Adet',
            key: 'quantity',
            width: 150,
            render: (text: string, record: any) =>
                record.isGroup ? null : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Input
                            value={
                                record.quantity !== null && record.quantity !== undefined
                                    ? record.quantity
                                    : ''
                            }
                            className="table-input"
                            disabled={isEdit}
                            min={0}
                            style={{ width: '80px' }}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                const val = parseInt(e.target.value);
                                handleInputChange(isNaN(val) ? null : val, record, 'quantity');
                            }}
                        />
                        {/* Hata mesajı gösterimi: 
                            1) row.quantityError varsa onu göster
                            2) Yoksa, eğer row seçili ve quantity yoksa "Bir değer giriniz" */}
                        {isRowSelected(record) && (
                            <>
                                {record.quantityError ? (
                                    <Text type="danger" style={{ fontSize: '12px', marginTop: '5px' }}>
                                        {record.quantityError}
                                    </Text>
                                ) : (
                                    (record.quantity === null ||
                                        record.quantity === undefined ||
                                        isNaN(record.quantity)) && (
                                        <Text type="danger" style={{ fontSize: '12px', marginTop: '5px' }}>
                                            Bir değer giriniz
                                        </Text>
                                    )
                                )}
                            </>
                        )}
                    </div>
                )
        },
        {
            title: 'Periyot (Yılda)',
            key: 'period',
            render: (text: string, record: any) =>
                record.isGroup ? null : (
                    <Input
                        value={record.period}
                        type="number"
                        className="table-input"
                        disabled={isEdit}
                        min={1}
                        defaultValue={1}
                        style={{ width: '80px' }}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            const val = parseInt(e.target.value);
                            handleInputChange(isNaN(val) ? 1 : val, record, 'period');
                        }}
                    />
                )
        }
    ];

    // Gruplama
    const groupedData: any[] = [];
    data.forEach((item: any) => {
        if (!item.toolId) {
            groupedData.push({ ...item, key: String(item.id), isGroup: false });
        } else {
            const tool = tools.find((t: any) => t.id === item.toolId);
            const toolName = tool ? tool.name : 'Diğer';
            const toolDesc = tool ? tool.description : 'Diğer';

            let group = groupedData.find(
                (g: any) => g.toolId === item.toolId && g.isGroup
            );
            if (!group) {
                group = {
                    key: 'group-' + item.toolId,
                    name: toolName,
                    isGroup: true,
                    description: toolDesc,
                    toolId: item.toolId,
                    children: []
                };
                groupedData.push(group);
            }
            group.children.push({ ...item, key: String(item.id), isGroup: false });
        }
    });

    // Arama ve showSelected
    const treeData = groupedData
        .map((group) => {
            if (group.isGroup) {
                const filteredChildren = group.children.filter((child: any) => {
                    const searchLower = searchText.toLocaleLowerCase('tr');
                    return (
                        (child?.name?.toLocaleLowerCase('tr') || '').includes(searchLower) ||
                        (child?.description?.toLocaleLowerCase('tr') || '').includes(searchLower)
                    );
                });
                return { ...group, children: filteredChildren };
            }
            const searchLower = searchText.toLocaleLowerCase('tr');
            return (group?.name?.toLocaleLowerCase('tr') || '').includes(searchLower) ||
                (group?.description?.toLocaleLowerCase('tr') || '').includes(searchLower)
                ? group
                : null;
        })
        .filter((group) => {
            if (group?.isGroup) {
                return group.children && group.children.length > 0;
            }
            return group !== null;
        })
        .sort((a: any, b: any) => {
            if (a.order == null && b.order == null) {
                return 0;
            } else if (a.order == null) {
                return 1;
            } else if (b.order == null) {
                return -1;
            } else {
                return a.order - b.order;
            }
        });

    const handleGroupCheckboxChange = (group: any) => {
        const groupItemIds = group.children.map((item: any) => item.id);
        const isAllSelected = groupItemIds.every((id: any) =>
            selectedRows.some((row) => row.parametreId === id)
        );

        let newSelectedRows = [...selectedRows];
        if (isAllSelected) {
            // grubu kaldır
            newSelectedRows = newSelectedRows.filter(
                (row) => !groupItemIds.includes(row.parametreId)
            );
        } else {
            // grubu ekle
            const itemsToAdd = group.children.map((item: any) => ({
                parametreId: item.id,
                birimFiyat: item.price,
                quantity: item.quantity ?? null,
                period: item.period ?? 1,
                fiyat: item.price * (item.quantity ?? 0)
            }));
            newSelectedRows = [...newSelectedRows, ...itemsToAdd];
        }

        // Tekilleştir
        let uniqueRows = uniqueByParametreId(newSelectedRows);

        // Sıralama
        uniqueRows.sort((a, b) => {
            const recA = data.find((itm) => itm.id === a.parametreId);
            const recB = data.find((itm) => itm.id === b.parametreId);
            const orderNoA = recA?.order ?? 999999;
            const orderNoB = recB?.order ?? 999999;
            return orderNoA - orderNoB;
        });

        setSelectedRows(uniqueRows);
    };

    const isGroupSelected = (group: any) => {
        const groupItemIds = group.children.map((item: any) => item.id);
        return groupItemIds.every((id: any) =>
            selectedRows.some((row) => row.parametreId === id)
        );
    };

    const isGroupIndeterminate = (group: any) => {
        const groupItemIds = group.children.map((item: any) => item.id);
        const selectedCount = groupItemIds.filter((id: any) =>
            selectedRows.some((row) => row.parametreId === id)
        ).length;
        return selectedCount > 0 && !isGroupSelected(group);
    };

    const generateOfferingLogsTooltip = () => {
        return (
            <div
                style={{
                    padding: '10px',
                    borderRadius: '8px',
                    color: '#fff',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    fontWeight: 'bold',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    width: '350px'
                }}
            >
                {offeringLogs?.map((offering: any, index) => (
                    <div key={index} style={{ marginBottom: '15px' }}>
                        <strong style={{ fontSize: '16px' }}>
                            {offering.offeringName}
                        </strong>
                        <ul style={{ paddingLeft: '20px', marginTop: '10px' }}>
                            {offering.attributes.map((attr: any, idx: any) => (
                                <li
                                    key={idx}
                                    style={{ fontSize: '14px', marginBottom: '5px' }}
                                >
                                    {attr.attributeName} -{' '}
                                    <span style={{ fontWeight: 'normal' }}>Adet:</span>{' '}
                                    {attr.quantity}
                                </li>
                            ))}
                        </ul>
                        <div style={{ marginTop: '10px', fontSize: '14px' }}>
                            {offering.serviceFeeDifference > 0 && (
                                <Text style={{ color: '#fff' }}>
                                    Servis Fiyatı:{' '}
                                    {formatToTL(offering.serviceFeeDifference)}
                                </Text>
                            )}
                            <br />
                            <Text style={{ color: '#fff' }}>
                                Son Fiyat: {formatToTL(offering.finalPrice)}
                            </Text>
                        </div>
                        {index !== offeringLogs?.length - 1 && (
                            <Divider style={{ background: 'gray', height: '2px' }} />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    // Seçili veriler
    const selectedData = useMemo(() => {
        const uniqueRows = uniqueByParametreId(selectedRows);
        return uniqueRows
            .map((row) => data.find((item) => item.id === row.parametreId))
            .filter(Boolean)
            .sort((a: any, b: any) => {
                if (a.order == null && b.order == null) {
                    return 0;
                } else if (a.order == null) {
                    return 1;
                } else if (b.order == null) {
                    return -1;
                } else {
                    return a.order - b.order;
                }
            });
    }, [selectedRows, data]);

    // "Varsayılan" gruplar açık gelsin
    useEffect(() => {
        if (!hasInitialized.current && treeData && treeData.length > 0) {
            const defaultExpandedKeys = treeData
                .filter((item) => item.isGroup)
                .map((item) => item.key);
            setExpandedRowKeys(defaultExpandedKeys);
            hasInitialized.current = true;
        }
    }, [treeData]);

    // Döviz tooltip
    const usdValueRevenue = (revenueShare * usdRate).toFixed(2);
    const eurValueRevenue = (revenueShare * eurRate).toFixed(2);
    const usdValueTotal = (totalAmount * usdRate).toFixed(2);
    const eurValueTotal = (totalAmount * eurRate).toFixed(2);

    const revenueTooltip = (
        <div>
            <div style={{ marginBottom: '6px' }}>
                <strong>USD:</strong> {usdValueRevenue} $
            </div>
            <div>
                <strong>EUR:</strong> {eurValueRevenue} €
            </div>
        </div>
    );

    const totalPriceTooltip = (
        <div>
            <div style={{ marginBottom: '6px' }}>
                <strong>USD:</strong> {usdValueTotal} $
            </div>
            <div>
                <strong>EUR:</strong> {eurValueTotal} €
            </div>
        </div>
    );

    const hasProposalShow = permissions.includes('proposal.show');

    return (
        <>
            <Row justify="space-between" align="top">
                <Col>
                    <h2 className="text-start mt-10px">Parametre Listesi</h2>
                </Col>
                <Col>
                    <Row align="middle" justify="center">
                        <div className="text-right">
                            <Row>
                                <Button
                                    type={!showSelected ? 'default' : 'primary'}
                                    className="d-button fs-14px weight-500 mr-16px width-auto"
                                    onClick={() => setShowSelected(true)}
                                >
                                    Seçili Parametreler
                                </Button>
                                <Button
                                    type={showSelected ? 'default' : 'primary'}
                                    className="d-button fs-14px weight-500 width-auto"
                                    onClick={() => setShowSelected(false)}
                                >
                                    Tüm Parametreler
                                </Button>
                            </Row>
                        </div>
                        <Input
                            placeholder="Ara"
                            prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                            className="d-input d-input-form ml-16px d-input-filter"
                            style={{ width: 200 }}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </Row>
                    <Row justify={'end'}>
                        <Link to={'/tickets?parametres=help'} className="mt-10px mb-10px">
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    textDecoration: 'underline'
                                }}
                            >
                                Aradığınız ekipman/tesisat bilgisini bulamıyor musunuz?
                            </Text>
                        </Link>
                    </Row>
                </Col>
            </Row>

            <Table
                scroll={{ x: 768 }}
                columns={columns}
                dataSource={showSelected ? selectedData : treeData}
                pagination={false}
                rowKey="key"
                expandable={{
                    expandRowByClick: true,
                    rowExpandable: (record) => record.isGroup,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => {
                        if (expanded) {
                            setExpandedRowKeys((prev) => [...prev, record.key]);
                        } else {
                            setExpandedRowKeys((prev) =>
                                prev.filter((key) => key !== record.key)
                            );
                        }
                    }
                }}
                expandedRowKeys={expandedRowKeys}
                onRow={(record) => {
                    if (!record.isGroup) {
                        return {
                            onClick: (event) => {
                                event.stopPropagation();
                                // row'a tıklandığında isterseniz handleRowSelection(record) çağırabilirsiniz
                            }
                        };
                    }
                    return {};
                }}
            />

            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                {offeringLogs?.map((offering: any, index) => (
                    <div key={index} style={{ marginBottom: '15px' }}>
                        <div style={{ marginTop: '10px', fontSize: '14px' }}>
                            {offering.serviceFeeDifference > 0 && (
                                <>
                                    <Text strong>
                                        {offering.offeringName} servis fiyatı:{' '}
                                        {formatToTL(offering.serviceFeeDifference)}
                                    </Text>
                                    <br />
                                </>
                            )}
                        </div>
                    </div>
                ))}

                <Text strong>
                    <Text strong style={{ cursor: 'pointer' }}>
                        <Tooltip title={totalPriceTooltip}>
                            Toplam Fiyat: {formatToTL(totalAmount)}
                        </Tooltip>
                    </Text>
                    <Tooltip
                        title={generateOfferingLogsTooltip()}
                        overlayStyle={{
                            maxWidth: '450px',
                            overflowY: 'auto',
                            maxHeight: '300px'
                        }}
                    >
                        <InfoCircleOutlined style={{ color: '#1890ff', marginLeft: '8px' }} />
                    </Tooltip>
                </Text>

                {hasProposalShow && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            justifyContent: 'end',
                            marginTop: 10
                        }}
                    >
                        <Tooltip title={revenueTooltip}>
                            <Text type="success" style={{ cursor: 'pointer' }}>
                                Potansiyel kazancım: {formatToTL(revenueShare)}
                            </Text>
                        </Tooltip>
                        <Tooltip title="Gelirinizden, Gelir Vergisi düşülmüş halidir. Firma ile sözleşme yapılması durumunda, fatura tutarına göre kazancınız yeniden hesaplanacaktır. Net kazancınızı cüzdan sayfanızdan kolayca takip edebilirsiniz.">
                            <InfoCircleOutlined
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                            />
                        </Tooltip>
                    </div>
                )}
                <br />
            </div>

            {!isEdit && (
                <div style={{ marginTop: '20px', textAlign: 'right' }}>
                    <Popconfirm
                        title="Teklifi iptal etmek istediğinize emin misiniz?"
                        onConfirm={() => {
                            navigate('/proposals', { replace: true });
                            window.location.reload();
                        }}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            type="default"
                            className="d-button fs-16px weight-500 width-auto mr-10px"
                        >
                            Teklifi İptal Et
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Teklif oluşturmak istediğinize emin misiniz?"
                        onConfirm={handleTeklifOlustur}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            type="primary"
                            className="d-button fs-16px weight-500 width-auto dark-button"
                            loading={loading}
                        >
                            Teklif Oluştur
                        </Button>
                    </Popconfirm>
                </div>
            )}
            {showPdfUpload && (
                <PdfUpload proposalId={selectedId} />
            )}
        </>
    );
};

/**
 * Parametreleri `parametreId` bazında tekilleştiren yardımcı fonksiyon.
 */
function uniqueByParametreId(rows: any[]) {
    const seen = new Set();
    return rows.filter((item) => {
        if (seen.has(item.parametreId)) {
            return false;
        }
        seen.add(item.parametreId);
        return true;
    });
}

export default Parametres;