import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Dropdown, Menu, Input, Row, Col, Tag,
  Typography, Card, Avatar, Modal, Form, InputNumber, Tooltip,
  Divider, Select, Checkbox, DatePicker
} from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import ProposalService from '../../services/ProposalService';
import {
  capitalizeWords,
  formatDate,
  formatToTL,
  openNotification
} from '../../utils';
import CompanyService from '../../services/CompanyService';
import StatusService from '../../modules/Status/Service/StatusService';
import tinycolor from 'tinycolor2';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import PlanningModal from '../Companies/PlanningModal';
import DocumentUploadModal from '../Companies/UploadModal';
import './ProposalCompanySelect.scss';
import ProposalView from './ProposalView';
import ReportInvoiceModal from '../ProjectFollowUp/ReportInvoiceModal';

// Türkçe dil/format ayarları
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const ProposalList: React.FC<any> = ({ defaultStatusIds = [], type = 1 }) => {
  const [filteredProposals, setFilteredProposals] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState<any | null>(null);
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Başlangıç sayfasını 1 olarak ayarladık
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // Logo için cache/pending
  const logoCache = new Map();
  const pendingRequests = new Map();

  // Planlama modal
  const [selectedProposalId, setSelectedProposalId] = useState<number | null>(null);
  const [isPlanningModalVisible, setIsPlanningModalVisible] = useState(false);

  // Durumlar
  const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);

  // "proposal.show" izni kontrolü için:
  const [permissions, setPermissions] = useState<string[]>([]);

  // Sıralama
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC');

  // Sadece kendi tekliflerimi listele
  const [onlyMyCompanies, setOnlyMyCompanies] = useState<boolean>(false);
  const userId = localStorage.getItem('id');

  // Rapor & Fatura Modal
  const [invoiceModalData, setInvoiceModalData] = useState<any>();
  const [invoiceModalType, setInvoiceModalType] = useState<any>();
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);

  // Tarih aralığı
  const [dateRange, setDateRange] = useState<[string | null, string | null]>([null, null]);

  // Kullanıcı "İş Ortağı" mı?
  const isPartner = localStorage.getItem('isPartner');

  // 2) Statüleri çek
  useEffect(() => {
    StatusService.getAll()
      .then((response: any) => {
        const filteredStatuses = response.data.filter((status: any) =>
          defaultStatusIds.includes(status.id)
        );
        setStatuses(filteredStatuses);
      })
      .catch((error) => console.error('Statüler yüklenirken hata oluştu:', error));
  }, [defaultStatusIds]);

  // 3) Filtre / pagination / sort değişince veriyi fetch
  useEffect(() => {
    fetchProposals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    sortField,
    sortDirection,
    isPlanningModalVisible,
    isUploadModalVisible,
    searchText,
    defaultStatusIds,
    selectedStatusIds,
    onlyMyCompanies,
    userId,
    dateRange,
    permissions
  ]);

  // 4) Upload modal kapanınca tekrar veriyi çek
  useEffect(() => {
    if (!isUploadModalVisible) {
      fetchProposals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadModalVisible]);

  // "onlyMyCompanies" veya diğer filtre değiştiğinde sayfayı sıfırla
  useEffect(() => {
    setCurrentPage(1);
  }, [onlyMyCompanies]);

  const fetchProposals = async () => {
    logoCache.clear();
    pendingRequests.clear();

    const [start, end] = dateRange;
    const params: any = {
      page: currentPage - 1, // 0-based
      size: pageSize,
      company: searchText,
      statuIds: selectedStatusIds.length > 0
        ? selectedStatusIds.join(',')
        : defaultStatusIds.join(','),
      sortField: sortField,
      sortDirection: sortDirection,
      startDate: start,
      endDate: end,
    };

    if (onlyMyCompanies && userId) {
      params.accountId = userId;
    }

    // "proposal.show" iznine göre getAll veya getMe
    let fetchFn;
    if (permissions.includes('proposal.show')) {
      fetchFn = ProposalService.getAll;
    } else {
      fetchFn = ProposalService.getMe;
    }

    try {
      const response: any = await fetchFn(params);
      // Gelen verileri enrichProposalsWithLogos ile zenginleştir
      const enrichedProposals = await enrichProposalsWithLogos(response.data.content);

      // type === 2 => sadece statusId != null ise (örnek senaryo)
      const filteredByStatus =
        type === 2
          ? enrichedProposals.filter((pr: any) => pr.statusId != null)
          : enrichedProposals;

      setFilteredProposals(filteredByStatus);
      setTotalItems(response.data.totalElements);
    } catch (error) {
      console.error('Teklif verileri alınırken hata oluştu:', error);
    }
  };

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    setSortField(sorter.field || 'createdAt');
    setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
  };

  // Logo zenginleştirme
  const enrichProposalsWithLogos = async (proposals: any[]) => {
    return Promise.all(
      proposals.map(async (proposal) => {
        const { company } = proposal;
        if (company.imageId) {
          if (logoCache.has(company.imageId)) {
            return {
              ...proposal,
              company: { ...company, logoUrl: logoCache.get(company.imageId) },
            };
          } else if (pendingRequests.has(company.imageId)) {
            await pendingRequests.get(company.imageId);
            return {
              ...proposal,
              company: { ...company, logoUrl: logoCache.get(company.imageId) },
            };
          } else {
            const logoPromise = CompanyService.getImage(company.imageId)
              .then((logoResponse) => {
                const logoUrl = logoResponse.data.preSignUrl;
                logoCache.set(company.imageId, logoUrl);
                pendingRequests.delete(company.imageId);
                return logoUrl;
              })
              .catch((error) => {
                console.error('Logo yüklenirken hata oluştu:', error);
                pendingRequests.delete(company.imageId);
                return null;
              });

            pendingRequests.set(company.imageId, logoPromise);
            const logoUrl = await logoPromise;
            return { ...proposal, company: { ...company, logoUrl } };
          }
        }
        return proposal;
      })
    );
  };

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case 'download':
        break;
      case 'revise':
        handleOfferEditNavigation(record.id);
        break;
      case 'applyDiscount':
        setSelectedProposal(record);
        setIsDiscountModalVisible(true);
        break;
      case 'cancelProposal':
        handleUpdateStatus(record.id, 2);
        break;
      case 'uploadDocument':
        setSelectedProposalId(record.id);
        setSelectedProposal(record);
        setIsUploadModalVisible(true);
        break;
      case 'requestPlanningDate':
        setSelectedProposalId(record.id);
        setIsPlanningModalVisible(true);
        break;
      case 'sendProposal':
        handleUpdateStatus(record.id, 3, 1);
        break;
      default:
        break;
    }
  };

  const handleUpdateStatus = async (id: any, statusId: any, _type: any = 0) => {
    try {
      await ProposalService.update(id, { statusId });
      if (_type === 1) {
        openNotification('success', 'Başarılı', 'Teklif Gönderildi.');
      } else {
        openNotification('success', 'Başarılı', 'Teklif Güncellendi.');
      }
      fetchProposals();
    } catch (error) {
      openNotification('error', 'Hata', 'Teklif iptal edilirken bir hata oluştu.');
      console.error(error);
    }
  };

  // Modal: İndirim Uygula
  const handleModalOk = async () => {
    if (selectedProposal && discountedPrice !== null) {
      try {
        await ProposalService.updatePrice(selectedProposal.id, {
          newTotalPrice: discountedPrice,
        });
        fetchProposals();
        setIsDiscountModalVisible(false);
        setDiscountedPrice(null);
        form.setFieldValue('discountedPrice', null);
      } catch (error) {
        console.error('Fiyat güncellenirken hata oluştu:', error);
      }
    }
  };

  const handleModalCancel = () => {
    setIsDiscountModalVisible(false);
    setIsUploadModalVisible(false);
    setDiscountedPrice(null);
    setIsPlanningModalVisible(false);
  };

  const getStatusNameById = (statusId: number) => {
    const status = statuses.find((s: any) => s.id === statusId);
    return status ? status.name : 'Bilinmiyor';
  };

  const getStatusColorById = (statusId: number) => {
    const status = statuses.find((s: any) => s.id === statusId);
    return status ? `${status.color}` : '#fdfdfd';
  };

  // Rapor & Fatura Modal'a açılış
  const handleOpenInvoiceModal = (proposal: any, mode: 'edit' | 'view') => {
    if (!proposal.id) {
      console.error("Proposal ID is missing");
      openNotification("error", "Hata", "Teklif ID'si bulunamadı.");
      return;
    }

    setSelectedProposalId(proposal.id);
    setInvoiceModalData({
      invoiceAmount: proposal.invoiceAmount || '',
      reportUrl: proposal.reportUrl || '',
      financialInfoUrl: proposal.financialInfoUrl || '',
      invoiceDate: proposal.invoiceDate || '',
    });

    setIsInvoiceModalVisible(true);
    setInvoiceModalType(mode);
  };

  const renderPriceTooltipContent = (priceCalculationResponse: any) => {
    if (!priceCalculationResponse || !priceCalculationResponse.offeringLogs) {
      return <Text>Veri mevcut değil</Text>;
    }

    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '10px' }}>
        {priceCalculationResponse.offeringLogs.map((log: any, index: number) => (
          <div key={index} style={{ marginBottom: '15px' }}>
            <strong>{log.offeringName}</strong>
            <ul style={{ paddingLeft: '20px', marginTop: '10px' }}>
              {log.attributes.map((attribute: any, idx: number) => (
                <li key={idx}>
                  <span>
                    {attribute.attributeName} - {attribute.quantity} adet -{' '}
                    {formatToTL(attribute.price)}
                  </span>
                  <p style={{ color: '#999', fontSize: '12px' }}>
                    {attribute.description}
                  </p>
                </li>
              ))}
            </ul>
            {log.serviceFeeDifference > 0 && (
              <p style={{ color: '#f0ad4e' }}>
                Servis Fiyatı Farkı: {formatToTL(log.serviceFeeDifference)}
              </p>
            )}
            <p>Toplam: {formatToTL(log.finalPrice)}</p>
            {index !== priceCalculationResponse.offeringLogs.length - 1 && (
              <Divider style={{ background: 'gray', height: '2px' }} />
            )}
          </div>
        ))}
      </div>
    );
  };

  // Tablo kolonları
  const columns = [
    {
      title: 'Firma Adı',
      dataIndex: ['company', 'name'],
      key: 'companyName',
      render: (text: any, record: any) => {
        const displayText =
          text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center">
            <div className="avatar-image-logo" style={{ marginRight: 10 }}>
              {record?.company?.logoUrl ? (
                <img
                  src={record?.company?.logoUrl}
                  alt={record?.company?.name}
                  className="avatar-image-logo"
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <Avatar
                  className="avatar-image-logo"
                  style={{ backgroundColor: '#f0f0f0', color: '#000' }}
                >
                  {record?.company?.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>

            <Tooltip title={text.toUpperCase()}>
              <span>{displayText.toUpperCase()}</span>
            </Tooltip>
          </div>
        );
      },
    },
    // "proposal.show" iznine sahipse "İş Ortağı Ad Soyad" kolonu eklenir
    ...(permissions.includes('proposal.show')
      ? [
        {
          title: 'İş Ortağı Ad Soyad',
          key: 'accountOwner',
          render: (text: any, record: any) =>
            record.account
              ? `${record.account.firstName} ${record.account.lastName}`
              : 'Bilgi Yok',
        },
      ]
      : []),
    {
      title: 'Fiyat',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (price: number, record: any) =>
        price ? (
          <div className="price-tooltip">
            <span>{formatToTL(price)}</span>
            <Tooltip
              title={renderPriceTooltipContent(
                record.priceCalculationResponse
              )}
              overlayStyle={{ maxWidth: '450px', maxHeight: '300px' }}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
            </Tooltip>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'Kontrol Adresi',
      dataIndex: ['address', 'addressLine1'],
      key: 'controlAddress',
      width: 200,
      render: (text: any, record: any) => {
        const addressLine1 = capitalizeWords(record?.address?.addressLine1 || '');
        const city = capitalizeWords(record?.address?.city || '');
        let combinedText;
        if (addressLine1 !== '' && city !== '') {
          combinedText = `${addressLine1}, ${city}`;
        } else {
          combinedText = '-';
        }

        return combinedText && combinedText.length > 40 ? (
          <Tooltip title={combinedText}>
            <Text>{combinedText.slice(0, 40)}...</Text>
          </Tooltip>
        ) : (
          <Text>{combinedText || '-'}</Text>
        );
      },
    },
    {
      title: 'Teklif Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (date: string) =>
        date ? <Text>{formatDate(date)}</Text> : <span>-</span>,
    },
    {
      title: 'Teklif No',
      dataIndex: 'proposalNumber',
      key: 'proposalNumber',
      render: (proposalNumber: string) =>
        proposalNumber ? (
          <span style={{ color: 'blue' }}>{proposalNumber}</span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Statü',
      dataIndex: 'statusId',
      key: 'status',
      render: (statusId: number) => {
        const color = tinycolor(getStatusColorById(statusId));
        const transparentColor = color.setAlpha(0.1).toRgbString();
        const transparentColorBorder = color.setAlpha(0.5).toRgbString();

        return (
          <Tag
            style={{
              color: getStatusColorById(statusId),
              backgroundColor: transparentColor,
              borderColor: transparentColorBorder,
              borderRadius: '16px',
              padding: '5px 10px',
              fontSize: '14px',
              display: 'inline-block',
              minWidth: '100px',
              textAlign: 'center',
              fontWeight: 500,
            }}
          >
            {getStatusNameById(statusId)}
          </Tag>
        );
      },
    },
    {
      title: '',
      key: 'action',
      render: (_text: string, record: any) => (
        <Dropdown
          overlay={
            record.proposalNumber !== null ? (
              <Menu onClick={({ key }) => handleMenuClick(key, record)}>
                {type === 1 && (record.statusId === 11) && (
                  <Menu.Item key="sendProposal">Teklifi Gönder</Menu.Item>
                )}
                {type === 1 &&
                  record.statusId !== 1 &&
                  record.statusId !== null && (
                    <Menu.Item key="download">
                      <ProposalView proposalId={record.id} />
                    </Menu.Item>
                  )}
                {record.statusId !== null &&
                  (record.statusId === 3 || record.statusId === 11 || record.statusId === 9) &&
                  record.statusId !== 2 && (
                    <Menu.Item key="revise">Revize Et</Menu.Item>
                  )}
                {type === 2 && record.statusId === 6 && (
                  <Menu.Item key="requestPlanningDate">
                    Planlama Talebi Oluştur
                  </Menu.Item>
                )}
                {type === 1 &&
                  (record.statusId === 3 || record.statusId === 10) && (
                    <Menu.Item key="uploadDocument">Sözleşme Yükle</Menu.Item>
                  )}
                {type === 1 &&
                  (record.statusId === 3 || record.statusId === 11) && (
                    <Menu.Item key="applyDiscount">İndirim Uygula</Menu.Item>
                  )}
                {type === 1 && record.statusId === 11 && (
                  <Menu.Item key="cancelProposal">Teklifi İptal Et</Menu.Item>
                )}
                {type === 2 &&
                  (record.statusId === 4 ||
                    record.statusId === 7 ||
                    record.statusId === 8) && (
                    <Menu.Item
                      key="reportView"
                      disabled={
                        record.financialInfoUrl == null ||
                        record.invoiceAmount == null ||
                        record.reportUrl == null
                      }
                      onClick={() => handleOpenInvoiceModal(record, 'view')}
                    >
                      Rapor & Fatura Görüntüle
                    </Menu.Item>
                  )}
              </Menu>
            ) : (
              <Menu>
                <Menu.Item
                  key="create"
                  onClick={() => handleNavigateCreate(record?.company?.id)}
                >
                  Teklif Oluştur
                </Menu.Item>
              </Menu>
            )
          }
        >
          <Button
            type="default"
            className="d-button action-button"
          >
            İşlemler
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchText(value);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleStatusChange = (value: number[]) => {
    setSelectedStatusIds(value);
    setCurrentPage(1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target?.value?.toLowerCase();
    handleSearch(searchValue);
  };

  const handleOfferEditNavigation = (id: number) => {
    navigate(`/proposals/create?proposalId=${id}`);
  };

  const handleNavigateCreate = (companyId: any = null) => {
    if (companyId == null) {
      return navigate('/proposals/create');
    }
    navigate(`/proposals/create?companyId=${companyId}`);
  };

  // Date Range seçimi
  const onDateRangeChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates) {
      setDateRange([
        dates[0]?.format('YYYY-MM-DD') ?? null,
        dates[1]?.format('YYYY-MM-DD') ?? null
      ]);
    } else {
      setDateRange([null, null]);
    }
  };

  const handleCloseInvoiceModal = () => {
    setIsInvoiceModalVisible(false);
  };

  const onFieldsChange = () => {
    const errors = form.getFieldsError();
    const hasError = errors.some((field) => field.errors.length > 0);
    setHasFormError(hasError);
  };

  // İndirim OnChange
  const handleDiscountChange = (value: any) => {
    if (!value) {
      setDiscountedPrice(selectedProposal.totalPrice);
      return;
    }
    const newDiscountedPrice = selectedProposal.totalPrice - value;
    setDiscountedPrice(newDiscountedPrice);
  };

  // 1) permissions’ı localStorage'dan çek
  useEffect(() => {
    const storedPermissions = localStorage.getItem('permissions');
    if (storedPermissions) {
      try {
        const parsedPermissions = JSON.parse(storedPermissions);

        // Eğer JSON içeriği gerçekten bir array ise set et
        if (Array.isArray(parsedPermissions)) {
          setPermissions(parsedPermissions);
        } else {
          console.error('Permissions hatalı formatta:', parsedPermissions);
        }
      } catch (err) {
        console.error('Permissions parse hatası:', err);
      }
    }
  }, [statuses, defaultStatusIds]);

  return (
    <>
      <Card className="br-30px mb-120px proposal-card">
        <Row
          gutter={[16, 16]}
          align="middle"
          justify="space-between"
          className="mb-20px"
        >
          <Col span={9.5}>
            <Row align="middle">
              {type === 1 ? (
                <Title level={4} style={{ margin: 0 }}>
                  Teklif Takip Listesi
                </Title>
              ) : (
                <Title level={4} style={{ margin: 0 }}>
                  İş Takip Listesi
                </Title>
              )}
              {/* "proposal.show" izni varsa "Sadece kendi tekliflerimi" checkbox */}
              {permissions.includes('proposal.show') && (
                <Checkbox
                  checked={onlyMyCompanies}
                  onChange={(e) => setOnlyMyCompanies(e.target.checked)}
                  style={{ marginLeft: 16 }}
                >
                  Sadece kendi tekliflerimi listele
                </Checkbox>
              )}
            </Row>
          </Col>
          <Col span={15.5}>
            <Row justify={'end'} align="middle">
              <Select
                mode="multiple"
                placeholder="Statü seçin"
                className="d-input-filter"
                style={{
                  width: 200,
                  minHeight: 48,
                  marginRight: 10,
                  padding: 10,
                }}
                onChange={handleStatusChange}
              >
                {statuses.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>

              {/* Tarih Aralığı */}
              <RangePicker
                format="DD/MM/YYYY"
                locale={locale}
                className="d-input-filter mr-8px d-input-filter"
                style={{ marginRight: 10 }}
                onChange={onDateRangeChange}
              />

              <Input
                placeholder="Ara"
                prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                className="d-input d-input-filter mr-8px d-input-filter"
                style={{ width: 200 }}
                onChange={handleInputChange}
              />

              {type === 1 && (
                <Tooltip
                  title={
                    isPartner?.toString() === 'false'
                      ? 'Teklif oluşturmak için iş ortağı sözleşmesini onaylamanız gerekmektedir'
                      : ''
                  }
                >
                  <Button
                    type="primary"
                    className="d-button dark-button width-auto ml-10px"
                    onClick={() => handleNavigateCreate(null)}
                    disabled={isPartner?.toString() === 'false'}
                  >
                    Teklif Oluştur
                  </Button>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
        <Table
          scroll={{ x: 768 }}
          columns={columns}
          dataSource={filteredProposals}
          rowKey={(record, key) => record.id || key}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalItems,
            onChange: (page, pgSize) => {
              setCurrentPage(page);
              setPageSize(pgSize || 10);
            },
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
          }}
          onChange={handleTableChange}
        />

        {/* İndirim Modal */}
        <Modal
          title="İndirim Uygula"
          open={isDiscountModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          {selectedProposal && (
            <>
              <p>İndirimli fiyat teklifinizi hızlı bir şekilde hazırlayın.</p>

              <div style={{ marginBottom: 16 }}>
                <Text strong>Teklif Fiyatı</Text>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                  {formatToTL(selectedProposal.totalPrice)}
                </div>
              </div>

              <Form
                layout="vertical"
                form={form}
                onFieldsChange={onFieldsChange}
              >
                <Form.Item
                  label={`İndirim tutarını belirleyin (Kullanılabilir İndirim Tutarı: ${formatToTL(
                    selectedProposal.maxDiscountPrice
                  )})`}
                  name="discountAmount"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value === undefined || value === null) {
                          return Promise.resolve();
                        }
                        if (
                          typeof value === 'number' &&
                          value >= 0 &&
                          value <= selectedProposal.maxDiscountPrice
                        ) {
                          return Promise.resolve();
                        }
                        if (selectedProposal.maxDiscountPrice === 0) {
                          return Promise.reject(
                            new Error('Daha fazla indirim uygulayamazsınız!')
                          );
                        }
                        return Promise.reject(
                          new Error(
                            `İndirim tutarı 0 ile ${formatToTL(
                              selectedProposal.maxDiscountPrice
                            )} arasında olmalıdır!`
                          )
                        );
                      },
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    step={1}
                    precision={0}
                    style={{ width: '100%' }}
                    placeholder="İndirim Tutarı Giriniz"
                    className="d-input d-input-form d-flex align-items-center"
                    onKeyDown={(event) => {
                      // Virgül tuşunu engelle
                      if (event.key === ',') {
                        event.preventDefault();
                      }
                    }}
                    onChange={(value) => handleDiscountChange(value)}
                  />
                </Form.Item>

                <div style={{ marginTop: 16 }}>
                  <Text strong>Yeni Teklif Tutarı</Text>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#52c41a',
                      marginTop: '8px',
                    }}
                  >
                    {discountedPrice !== null
                      ? formatToTL(discountedPrice)
                      : formatToTL(selectedProposal.totalPrice)}
                  </div>
                </div>
              </Form>

              <div style={{ marginTop: 24, textAlign: 'center' }}>
                <Button
                  type="primary"
                  className="d-button dark-button width-auto p-20px"
                  disabled={hasFormError || selectedProposal.isTotalPriceSet}
                  onClick={() => {
                    form.validateFields()
                      .then(() => handleModalOk())
                      .catch((err) => {
                        console.log('Form error:', err);
                      });
                  }}
                >
                  İndirimli Teklifi Oluştur
                </Button>
              </div>
            </>
          )}
        </Modal>

        {/* Planlama Modal */}
        {isPlanningModalVisible && (
          <PlanningModal
            setIsModalVisible={setIsPlanningModalVisible}
            isModalVisible={isPlanningModalVisible}
            proposalId={selectedProposalId!}
            isCustomer={true}
          />
        )}

        {/* Sözleşme Yükleme Modal */}
        {isUploadModalVisible && (
          <DocumentUploadModal
            setIsModalVisible={setIsUploadModalVisible}
            isModalVisible={isUploadModalVisible}
            proposalId={selectedProposalId!}
            isCustomer={true}
          />
        )}
      </Card>

      {/* Rapor & Fatura Modal */}
      {isInvoiceModalVisible && selectedProposalId && (
        <ReportInvoiceModal
          visible={isInvoiceModalVisible}
          onClose={handleCloseInvoiceModal}
          onSubmit={() => null}
          initialValues={invoiceModalData}
          type={invoiceModalType}
        />
      )}
    </>
  );
};

export default ProposalList;