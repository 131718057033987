import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  Typography,
  message,
  Tooltip
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CheckCircleOutlined } from '@ant-design/icons';
import NotificationService from '../../services/NotificationService';

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

/**
 * Duyuru tipini Türkçe gösteren yardımcı fonksiyon
 */
function getTurkishType(type: string) {
  switch (type) {
    case 'INFORMATION':
      return 'Bilgilendirme';
    case 'UPDATE':
      return 'Güncelleme';
    case 'EVENT':
      return 'Etkinlik';
    default:
      return 'Diğer';
  }
}

/**
 * Backend "id /-/ type /-/ title /-/ message" formatı varsayılıyor.
 * "createdAt" ve "status" gibi alanlar item üzerinden geliyor olabilir.
 */
function parseRawMessage(raw: string, fallbackId: number | undefined, item: any) {
  if (!raw || !raw.includes('/-/')) return null;
  const parts = raw.split('/-/');
  if (parts.length < 4) return null;

  return {
    announcementId: parts[0].trim() || fallbackId || Math.random().toString(),
    id: fallbackId || 0,
    type: parts[1].trim(),
    title: parts[2].trim(),
    message: parts[3].trim(),
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    status: item.status || 'SENT'
  };
}

const AllNotificationsPage: React.FC = () => {
  const [notifications, setNotifications] = useState<any[]>([]);

  // Filtre alanları
  const [statusFilter, setStatusFilter] = useState<'ALL' | 'UNREAD'>('ALL');
  const [typeFilter, setTypeFilter] = useState<string>('ALL');
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  /**
   * Bildirimleri backend'den çekme
   */
  const fetchAllNotifications = async () => {
    try {
      const response = await NotificationService.getMeNotifications();
      if (Array.isArray(response.data)) {
        const parsedList = response.data
          .map((item: any) => parseRawMessage(item.message, item.id, item))
          .filter(Boolean);

        setNotifications(parsedList as any[]);
      }
    } catch (err) {
      console.error('Bildirimler çekilirken hata:', err);
      message.error('Bildirimler yüklenemedi!');
    }
  };

  // Okundu işaretleme
  const handleMarkAsRead = async (notifId: number) => {
    try {
      await NotificationService.markAsRead(notifId);
      // İşlem başarılı olunca listeyi yeniden yükle
      fetchAllNotifications();
    } catch (error) {
      console.error('Okundu işaretleme hatası:', error);
      message.error('Okundu işaretlemede hata oluştu!');
    }
  };

  // Filtre uygulanmış liste
  const filteredNotifications = notifications.filter((notif) => {
    // 1) Okunma durumu
    if (statusFilter === 'UNREAD') {
      if (notif.status !== 'SENT') return false;
    }

    // 2) Duyuru Tipi
    if (typeFilter !== 'ALL') {
      if (notif.type !== typeFilter) return false;
    }

    // 3) Tarih aralığı
    if (dateRange && dateRange[0] && dateRange[1]) {
      const notifDate = dayjs(notif.createdAt??notif.updatedAt);
      if (
        notifDate.isBefore(dateRange[0], 'day') ||
        notifDate.isAfter(dateRange[1], 'day')
      ) {
        return false;
      }
    }

    return true;
  });

  return (
    <div style={{ padding: '24px', marginBottom: 120 }}>
      {/* === 1. Kart: Filtre Alanları === */}
      <Card
        style={{ marginBottom: 24, borderRadius: 16 }}
        bodyStyle={{ padding: '24px' }}
      >
        <Title level={4} style={{ marginBottom: 24 }}>
          Duyuru Statüsü
        </Title>

        <Row gutter={[16, 16]} align="middle">
          {/* 1) Tümü / Okunmayanlar */}
          <Col xs={24} sm={8} md={6} lg={5}>
            <Radio.Group
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <Radio value="ALL">Tümü</Radio>
              <Radio value="UNREAD">Okunmayanlar</Radio>
            </Radio.Group>
          </Col>

          {/* 2) Duyuru Tipi */}
          <Col xs={24} sm={8} md={6} lg={5}>
            <Select
              value={typeFilter}
              className='d-input-filter'
              onChange={(val) => setTypeFilter(val)}
              style={{ width: '100%' }}
            >
              <Select.Option value="ALL">Hepsi</Select.Option>
              <Select.Option value="INFORMATION">Bilgilendirme</Select.Option>
              <Select.Option value="UPDATE">Güncelleme</Select.Option>
              <Select.Option value="EVENT">Etkinlik</Select.Option>
            </Select>
          </Col>

          {/* 3) Tarih aralığı */}
          <Col xs={24} sm={8} md={12} lg={6}>
            <RangePicker
              format="DD/MM/YYYY"
              className='d-input-filter'
              onChange={(values) => {
                if (values && values.length === 2) {
                  setDateRange(values);
                } else {
                  setDateRange(null);
                }
              }}
              placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
      </Card>

      {/* === 2. Kart: Duyurular Listesi === */}
      <Card style={{ borderRadius: 16 }} bodyStyle={{ padding: '24px' }}>
        {filteredNotifications.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '40px 0' }}>
            <Text>Hiç duyuru bulunamadı.</Text>
          </div>
        ) : (
          filteredNotifications.map((notif, idx) => (
            <div
              key={notif.id || idx}
              style={{
                backgroundColor: notif.status === 'SENT' ? '#f5f5f5' : '#ffffff',
                borderRadius: 8,
                padding: '16px',
                borderBottom: idx < filteredNotifications.length - 1 ? '1px solid #f0f0f0' : 0,
              }}
            >
              {/* Üst satır: Tür (sol), Tarih+Icon (sağ) */}
              <Row justify="space-between" style={{ marginBottom: 8 }}>
                <Col>
                  <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#888' }}>
                    {getTurkishType(notif.type)}
                  </Text>
                </Col>

                <Col>
                  {/* Tarih ve ikon yan yana */}
                  <Row align="middle" style={{ gap: 8 }}>
                    <Text style={{ color: '#666', fontSize: 14 }}>
                      {dayjs(notif.createdAt??notif.updatedAt).format('DD/MM/YYYY HH:mm')}
                    </Text>

                    {/* Okundu ikonu sadece status === 'SENT' ise */}
                    {notif.status === 'SENT' && (
                      <Tooltip title="Okundu Olarak İşaretle">
                        <CheckCircleOutlined
                          style={{ fontSize: 20, color: '#1890ff', cursor: 'pointer' }}
                          onClick={() => handleMarkAsRead(notif.id)}
                        />
                      </Tooltip>
                    )}
                  </Row>
                </Col>
              </Row>

              {/* Başlık */}
              <div style={{ marginBottom: 6 }}>
                <Text style={{ fontSize: 15, fontWeight: 'bold', color: '#000' }}>
                  {notif.title}
                </Text>
              </div>

              {/* Açıklama */}
              <div>
                <Text style={{ fontSize: 14, color: '#000' }}>
                  {notif.message}
                </Text>
              </div>
            </div>
          ))
        )}
      </Card>
    </div>
  );
};

export default AllNotificationsPage;