import { http } from "../http-common";

// Sayfalı sorgular
const getAll = (params: any) => {
  return http.get<Array<any>>("/proposals", { params });
};

const getMe = (params: any) => {
  return http.get<Array<any>>("/proposals/me", { params });
};

// Sayfalama olmadan sorgular
const getAllWithoutPagination = () => {
  return http.get<Array<any>>("/proposals/all");
};

const getMeWithoutPagination = () => {
  return http.get<Array<any>>("/proposals/me/all");
};

const get = (id: Number) => {
  return http.get<any>(`/proposals/${id}`);
};

const calculatePrice = (data: any) => {
  return http.post<any>("/proposals/calculate-price", data);
};

const create = (data: any) => {
  return http.post<any>("/proposals", data);
};

const update = (id: any, data: any) => {
  return http.put<any>(`/proposals/${id}`, data);
};

const updateDocumentId = (id: any, data: any) => {
  return http.put<any>(`/proposals/${id}/document`, data);
};

const updatePrice = (id: any, data: any) => {
  return http.put<any>(`/proposals/${id}/total-price`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/proposals/${id}`);
};

const getCounts = () => {
  return http.get<Array<any>>("/proposals/counts");
};

const getRenuew = () => {
  return http.get<Array<any>>("/proposals/renuew");
};

const getSummary = () => {
  return http.get<Array<any>>("/proposals/summary");
};

const getRenewSummary = () => {
  return http.get<Array<any>>("/proposals/renew-summary");
};

const getDocuments = () => {
  return http.get<Array<any>>("/proposals/count-documents");
};

const getAverage = () => {
  return http.get<Array<any>>("/proposals/average-paid");
};

const monthlyCounts = () => {
  return http.get<Array<any>>("/proposals/monthly-counts");
};

const incomeSharingDistribution = () => {
  return http.get<Array<any>>("/proposals/income-sharing-distribution");
};

const statusSummary = () => {
  return http.get<Array<any>>("/proposals/status-summary");
};

const summaryByDateStatus = (params: any) => {
  return http.get<any>("/proposals/summary-by-date-status", { params });
};

const ProposalService = {
  getAll,
  getMe,
  getAllWithoutPagination,
  getMeWithoutPagination,
  get,
  create,
  update,
  remove,
  updatePrice,
  calculatePrice,
  updateDocumentId,
  getCounts,
  getRenuew,
  getDocuments,
  getSummary,
  getRenewSummary,
  getAverage,
  monthlyCounts,
  incomeSharingDistribution,
  statusSummary,
  summaryByDateStatus, // Yeni metod burada eklendi
};

export default ProposalService;