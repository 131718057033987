import React, { useState } from 'react';
import { Tabs } from 'antd';
import UserAttemps from '../../views/Profile/UserAttemps';
import Companies from '../../views/Users/Companies';
import ProposalListWithDetails from '../../views/Users/ProposalList';
import ActivityTimeline from './ActivityTimeline';

const { TabPane } = Tabs;

const UserTabs: React.FC<any> = ({ profileData }) => {
  const [activeKey, setActiveKey] = useState('1'); // Varsayılan olarak ilk sekme seçili

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <Tabs activeKey={activeKey} onChange={onChange} tabBarStyle={{ marginBottom: 32 }}>
      <TabPane tab="Hesap Aktiviteleri" key="1">
        <UserAttemps profileData={profileData} />
      </TabPane>
      <TabPane tab="Firma Listesi" key="2">
        <Companies />
      </TabPane>
      <TabPane tab="İş Takip Listesi" key="3">
        <ProposalListWithDetails type={1} defaultStatusIds={[2,3,4,5,6,7,8,9,10,11,12]}/>
      </TabPane>
      <TabPane tab="Ödemeler" key="4">
        <ProposalListWithDetails type={2} defaultStatusIds={[4, 7, 8]} />
      </TabPane>
      <TabPane tab="Son Aktiviteler" key="5">
       <ActivityTimeline />
      </TabPane>
    </Tabs>
  );
};

export default UserTabs;